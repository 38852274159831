html {
  height: 100%;
  font-size: 100%;
}

body,
html {
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-size: 0.938em;
  font-family: $open-sans;
  color: $charcoal;
  line-height: 24px;
  @include bp(xs) {
    font-size: 0.813em;
    line-height: 22px;
  }
}

a {
  color: $teal;
  text-decoration: none;
  /* Careful, this breaks the word wherever it is without a hyphen */
  overflow-wrap: break-word;
  word-wrap: break-word;
  /* Adds a hyphen where the word breaks */
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;

  &:hover {
    text-decoration: underline;
  }
}

embed,
iframe,
object {
  max-width: 100%;
}

.action {
  & input[type="submit"].form-submit {
    width: auto;
  }
}

input[type="submit"] {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  &.form-submit {
    @include button();
  }
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

input {
  border-radius: 0;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none !important;
}

main {}

#user-login {
  @include max-layout();
  padding-bottom: 85px;
}

.chev-arrow {
  width: 8px;
  height: 11px;
  display: inline-block;
  margin-left: 10px;

  &.teal {
    background: url("#{$path-images}chev-arrow-teal-right.svg") no-repeat;
    background-size: contain;
    background-position: right;
  }
}

.pager {
  li {
    font-weight: 400;
    font-size: 1rem;
  }

  .pager-next,
  .pager-previous {
    a {
      width: 12px;
      height: 12px;
      display: inline-block;
      @include hide-text;
      vertical-align: middle;
      margin-top: -1px;
    }
  }

  .pager-next {
    a {
      background: url("#{$path-images}chev-arrow-charcoal-right.svg") no-repeat !important;
      background-size: contain;
    }
  }

  .pager-previous {
    a {
      background: url("#{$path-images}chev-arrow-charcoal-left.svg") no-repeat !important;
      background-size: contain;
    }
  }
}

.throbber {
  display: none;
}

.inline {
  &-iframe{
  //height: calc(85vh - 50px);
  }
  &-iframe,
  &-vimeo-iframe,
  &-youtube-iframe {
    width: 100%;
    border: none;
    margin: 20px 0;
    // height: calc(560px * 9/16);
    // @include bp(sm) {
    //   margin: 10px 0;
    // }
  }
   &-vimeo-iframe,
   &-youtube-iframe {
     height: calc(560px * 9/16);
     @include bp(sm) {
       margin: 10px 0;
     }
   }
}

.field-name-field-iframe-src {
  display: none !important;
}
