.l-main-content-boundary {
  @include max-layout();
  padding-top: 40px;
  @include bp(md) {
    padding-top: 65px;
  }

  &.sidebar-hidden {
    .l-main-content-stage {
      float: right;
    }
  }
}

.l-main-content-stage {
  max-width: 891px;
  width: 100%;
  @include bp(md) {
    margin-bottom: 86px;
  }
}

.l-bottom-content-region {
  clear: both;
}

.sidebar-present {
  &.l-main-content-boundary {
    @include bp(md) {
       @include display(flex);
      @include flex-direction(row-reverse);
      @include justify-content(space-between);
    }
  }

  &.banner-present {
    @include bp(md) {
      .l-main-content-stage {
        max-width: 1042px;
        padding-top: 15px;
      }
    }
  }

  .l-main-content-stage {
    @include bp(md) {
      padding-right: 85px;
      padding-left: 28px;
    }
  }

  .col-1 {
    @include bp(md) {
      padding-left: 138px;
    }
  }

  .col-2 {
    @include bp(md) {
       @include flex(1);
      margin: 0;
    }
  }
}

.banner-present {
  .col-2 {
    @include bp(md) {
      padding-right: 124px;
    }
  }

  &.sidebar-hidden {
    @include bp(md) {
      padding-right: 180px;
    }
  }
}
