.accordion {
  &-toggle {
    h3 {
      font-family: "Open Sans", sans-serif;
      font-size: 0.8125rem;
    }
  }
}

.tabs {
  position: absolute;
  z-index: 9999999;
  margin: -5px 0 0;
}

ul li.collapsed {
  list-style-image: none !important;
  list-style-type: none !important;
}

.approach-subheading h2 {
  margin: 0;
}

.color-teal {
  color: $teal;
  font-style: normal;
}

.color-blue {
  color: $blue;
  font-style: normal;
}

.color-red {
  color: $red;
  font-style: normal;
}

.color-black {
  color: #000000 !important;
  font-style: normal;
}

.views-exposed-form .views-exposed-widget {
  float: left;
  padding: 0.8em 0 0;
  margin-left: 0;
  @include bp(sm) {
    //margin-left: 15px;
  }
}

.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 1.15em;
}

.contextual-links-region {
  outline: none;
  position: relative;
  line-height: 18px;
}
// iframe {
//   width: 500px !important;
//   max-width: 100% !important;
//   padding: 0 !important;
//   margin: 15px -20px 0 !important;
//   box-sizing: border-box !important;
// }

.form-wrapper {
  width: 500px !important;
  max-width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  box-sizing: border-box !important;
}

.resource-divider {
  float: left;
}

.field.field-name-field-optional-byline {
  &.field-type-text.field-label-hidden {
    float: left;
  }
}
