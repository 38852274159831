h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
  font-weight: normal;
  font-family: $roboto-slab;
}

h1, .h1 {
  font-size: 2rem;
  line-height: 41px;
  margin: 0 0 em(10px);
  color: $teal;
  font-weight: 300;

  @include bp(md) {
    font-size: 1.75rem;
    margin: 0 0 em(10px);
  }
}

h2, .h2 {
  font-size: 1.75rem;
  line-height: 36px;
  margin: em(15px) 0 em(10px);
  font-weight: 700;

  @include bp(sm) {
    font-size: 1.5rem;
    line-height: 32px;
    margin: 0 0 em(10px);
  }

  &.component-heading {
    font-size: 1.375rem;
    line-height: 28px;

    @include bp(md) {
      font-size: 1.125rem;
      line-height: 24px;
    }
  }
}

h3, .h3 {
  font-size: 1.125rem;
  line-height: 22px;
  margin: 0 0 em(10px);
}

h4, .h4 {
  font-size: 16px;
  line-height: 28px;
  margin: 0 0 em(8px);

  @include bp(sm) {
    font-size: 22px;
    line-height: 32px;
    margin: 0 0 em(8px);
  }
}

h5, .h5 {
  font-size: em(14px, 16px);
  font-weight: normal;
  line-height: em(18px);
  margin: 0 0 em(8px);
}
