.hidden-md {
  display: block;

  @include bp(md) {
    display: none;
  }
}

.visible-md {
  display: none;

  @include bp(md) {
    display: block;
  }
}

.screenreader {
  position: absolute !important; /* Outside the DOM flow */
  height: 1px; width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}