
  @include keyframes(arrow-rise) {
    0%   { width: 21px; @include transition(width .3s ease); }
    100% { width: 67px; @include transition(width .3s ease); }
  }

  @include keyframes(arrow-fall) {
    100%   { width: 21px; @include transition(width .3s ease); }
    0% { width: 67px; @include transition(width .3s ease); }
  }



  @include keyframes(l_sides) {
    0%   { height: 0%; }
    100% { height: 100%;}
  }

  @include keyframes(r_sides) {
    0%   { height: 0%; }
    100% { height: 100%;}
  }

  @include keyframes(t_sides) {
    0%   { width: 0%; }
    100% { width: 100%;}
  }

  @include keyframes(b_sides) {
    0%   { width: 0%; }
    100% { width: 100%;}
  }
